<template>
  <div class="app-container">
    <div class="mytabel table-sm mr-0 ml-0 p-0">
      <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
        <div
          class="
            col-7
            p-0
            align-self-center
            font-weight-bold
            d-flex
            align-items-center
          "
        >
          <h5 class="d-inline mr-2 font-weight-bold">
            {{ $t("message.products") }}
          </h5>
          <crm-refresh @c-click="refresh()"></crm-refresh>
          <div class="text-center d-flex sorddata ml-3">
            <el-input
              class="ml-3"
              size="mini"
              :placeholder="$t('message.search')"
              prefix-icon="el-icon-search"
              v-model="filterForm.search"
              clearable
            ></el-input>
            <el-date-picker
              class="ml-3"
              size="mini"
              v-model="filterForm.start_date"
              type="date"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              :placeholder="$t('message.start_date')"
            ></el-date-picker>
            <el-date-picker
              class="ml-3"
              size="mini"
              v-model="filterForm.end_date"
              type="date"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              :placeholder="$t('message.end_date')"
            ></el-date-picker>
          </div>
        </div>
        <div
          class="
            col-5
            align-items-center align-self-center
            text-right
            pr-0
            d-flex
            justify-content-end
          "
        >
          <el-button
            class="mr-2"
            size="mini"
            @click="drawer.create.status = true"
            icon="el-icon-circle-plus-outline"
            >{{ $t("message.create") }}</el-button
          >
          <crm-column-settings
            :columns="columns"
            :modelName="'products'"
            @c-change="updateColumn"
          ></crm-column-settings>
        </div>
      </div>
      <table
        class="table table-bordered table-hover mr-0 ml-0 p-0 bg-white"
        v-loading="loadingData"
      >
        <crm-pagination
          :pagination="pagination"
          @c-change="updatePagination"
        ></crm-pagination>
        <thead>
          <tr>
            <crm-th
              :column="columns.id"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.name"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.code"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.sku"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.price"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.currency"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.category"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.vat"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.description"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.measurement"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.created_at"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.updated_at"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.settings"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
          </tr>

          <tr>
            <th v-if="columns.id.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.id"
                :placeholder="columns.id.title"
              ></el-input>
            </th>
            <th v-if="columns.name.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.name"
                :placeholder="columns.name.title"
              ></el-input>
            </th>
            <th v-if="columns.code.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.code"
                :placeholder="columns.code.title"
              ></el-input>
            </th>
            <th v-if="columns.sku.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.sku"
                :placeholder="columns.sku.title"
              ></el-input>
            </th>
            <th v-if="columns.price.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.price"
                :placeholder="columns.price.title"
              ></el-input>
            </th>
            <th v-if="columns.currency.show">
              <el-select
                filterable
                clearable
                :placeholder="columns.currency.title"
                size="mini"
                v-model="filterForm.currency"
              >
                <el-option
                  v-for="item in currencies"
                  :key="item.name + item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </th>
            <th v-if="columns.category.show">
              <el-select
                filterable
                clearable
                :placeholder="columns.category.title"
                size="mini"
                v-model="filterForm.category"
              >
                <el-option
                  v-for="item in categories"
                  :key="item.name + item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </th>
            <th v-if="columns.vat.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.vat"
                :placeholder="columns.vat.title"
              ></el-input>
            </th>
            <th v-if="columns.description.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.description"
                :placeholder="columns.description.title"
              ></el-input>
            </th>
            <th v-if="columns.measurement.show">
              <el-select
                filterable
                clearable
                :placeholder="columns.measurement.title"
                size="mini"
                v-model="filterForm.measurement"
              >
                <el-option
                  v-for="item in measurements"
                  :key="item.name + item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </th>
            <th v-if="columns.created_at.show">
              <el-date-picker
                size="mini"
                v-model="filterForm.created_at"
                :placeholder="columns.created_at.title"
                type="date"
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
              ></el-date-picker>
            </th>
            <th v-if="columns.updated_at.show">
              <el-date-picker
                size="mini"
                v-model="filterForm.updated_at"
                :placeholder="columns.updated_at.title"
                type="date"
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
              ></el-date-picker>
            </th>
            <th v-if="columns.settings.show"></th>
          </tr>
        </thead>
        <transition-group name="flip-list" tag="tbody">
          <tr v-for="product in list" :key="product.id" class="cursor-pointer">
            <td v-if="columns.id.show">{{ product.id }}</td>
            <td v-if="columns.name.show">{{ product.name }}</td>
            <td v-if="columns.code.show">{{ product.code }}</td>
            <td v-if="columns.sku.show">{{ product.sku }}</td>
            <td v-if="columns.price.show">{{ product.price }}</td>
            <td v-if="columns.currency.show">
              {{ getCurrencyName(product.currency_id) }}
            </td>
            <td v-if="columns.category.show">
              {{ getCategoryName(product.category_id) }}
            </td>
            <td v-if="columns.vat.show">{{ product.vat }}</td>
            <td v-if="columns.description.show">{{ product.description }}</td>
            <td v-if="columns.measurement.show">
              {{ getMeasurementName(product.measurement_id) }}
            </td>
            <td v-if="columns.created_at.show">
              {{ product.created_at }}
            </td>
            <td v-if="columns.updated_at.show">
              {{ product.updated_at }}
            </td>
            <td v-if="columns.settings.show" class="settings-td">
              <crm-setting-dropdown
                :model="product"
                name="products"
                :actions="actions"
                @edit="edit"
                @delete="destroy"
              ></crm-setting-dropdown>
            </td>
          </tr>
        </transition-group>
      </table>
    </div>

    <el-drawer
      size="90%"
      :visible.sync="drawer.create.status"
      :with-header="false"
      :ref="drawer.create.name"
      :before-close="beforeClose"
    >
      <CrmCreate :drawer-name="drawer.create.name" />
    </el-drawer>
  </div>
</template>
<script>
import CrmCreate from "./components/crm-create";
import { mapGetters, mapActions } from "vuex";
import list from "@/utils/mixins/list";
import drawer from "@/utils/mixins/drawer";
export default {
  name: "ProductsWarehouses",
  mixins: [list, drawer],
  data() {
    return {
      loadingData: false,
      drawer: {
        create: {
          name: "create",
          status: false,
        },
      },
    };
  },
  components: {
    CrmCreate,
  },
  async mounted() {
    if (this.currencies && this.currencies.length === 0)
      await this.loadCurrencies();
    if (this.categories && this.categories.length === 0)
      await this.loadCategories();
      await this.loadMeasurements();
  },
  computed: {
    ...mapGetters({
      list: "products/list",
      columns: "products/columns",
      pagination: "products/pagination",
      filter: "products/filter",
      sort: "products/sort",
      currencies: "currencies/inventory",
      categories: "categories/inventory",
      measurements: "measurements/inventory",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  methods: {
    ...mapActions({
      updateList: "products/index",
      updateSort: "products/updateSort",
      updateFilter: "products/updateFilter",
      updateColumn: "products/updateColumn",
      updatePagination: "products/updatePagination",
      editModel: "products/show",
      empty: "products/empty",
      delete: "products/destroy",
      refreshData: "products/refreshData",
      loadCurrencies: "currencies/inventory",
      loadCategories: "categories/inventory",
      loadMeasurements: "measurements/inventory",
    }),
    fetchData() {
      const query = { ...this.filter, ...this.pagination, ...this.sort };
      if (!this.loadingData) {
        this.loadingData = true;
        this.updateList(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            console.log(err);
            this.loadingData = false;
          });
      }
    },
    getCurrencyName(id) {
      const data = this.currencies.find((currency) => {
        return currency.id == id;
      });
      return data ? data.name : "";
    },
    getCategoryName(id) {
      const data = this.categories.find((category) => {
        return category.id == id;
      });
      return data ? data.name : "";
    },
    getMeasurementName(id) {
      const data = this.measurements.find((measurement) => {
        return measurement.id == id;
      });
      return data ? data.name : "";
    },
    beforeClose(done) {
      this.emptyModel();
      this.refreshData();
      done();
    },
    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch((err) => {});
    },
    async edit(model) {
      await this.editModel(model.id)
        .then(async (res) => {
          this.drawer.create.status = true;
        })
        .catch((err) => {})
        .finally(() => {});
    },
    destroy(model) {
      this.delete(model.id)
        .then((res) => {
          this.$alert(res);
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    emptyModel() {
      this.empty();
    },
  },
};
</script>
